import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import './styles.css'

function Navbar() {
  const { t, i18n } = useTranslation();

  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const menuBtn = document.querySelector('.menu-btn');
    let menuOpen = false;

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    menuBtn.addEventListener('click', () => {
      if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
      } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
      }
    });

    return () => {
      window.removeEventListener('resize', changeWidth)
  }
  }, [])

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };
  return (
    <div>
      		{ i18n.language == 'ar' && <p onClick={() => { i18n.changeLanguage("en")}} className="lang-footer">English</p> }
     			{ i18n.language == 'en' && <p onClick={() => { i18n.changeLanguage("ar")}}  className="lang-footer">العربيه</p> }
    <div className="overlay">
      
       <nav>
       <img className='logo-img-header' src={require('../../Assets/images/logos/Logo-full.png')} alt='clothes recycling إعادة تدوير الملابس'/>

       {(toggleMenu || screenWidth > 500) && (
      <ul className="list" id="myLinks">
          <li className="items active"><a className="menu-button" href="#">{t('Home')}</a></li>
          <li className="items"><a className="menu-button" href="#services">{t('Services')}</a></li>
          <li className="items"><a className="menu-button" href="#aboutUs">{t('Contact')}</a></li>
          <li className="items request"><a className="pickup-button" href="#form">{t('PickupOrder')}</a></li>
          
          { i18n.language == 'ar' && <li onClick={() => { i18n.changeLanguage("en")}} className="items lang">English</li> }
          { i18n.language == 'en' && <li onClick={() => { i18n.changeLanguage("ar")}}  className="items lang">العربيه</li> }

          <div className='social-media-flex-header'>
          <a href="whatsapp://send?text=Hello World!&phone=+971565388999"><img className='social-img' src={require('../../Assets/images/social-icons/whatsapp-green.png')} alt='#kswty'/></a>
          <a href="https://www.instagram.com/kswty_ae/"><img className='social-img' src={require('../../Assets/images/social-icons/instagram-green.png')} alt='#kswty'/></a>
          <a href="https://www.facebook.com/profile.php?id=100085481397299"><img className='social-img' src={require('../../Assets/images/social-icons/fb-green.png')} alt='#kswty'/></a>
          <a href="http://www.tiktok.com/@kswty_uae"><img className='social-img' src={require('../../Assets/images/social-icons/tiktok-green.png')} alt='#kswty'/></a>
          <a href="mailto:kswtyrecycling@gmail.com"><img className='social-img' src={require('../../Assets/images/social-icons/email-green.png')} alt='#kswty'/></a>
          <a href="https://twitter.com/kswty_ae"><img className='social-img' src={require('../../Assets/images/social-icons/twitter-green.png')} alt='#kswty'/></a>
		  <a href="https://www.snapchat.com/add/kswty_ae?share_id=dncBimNJy0U&locale=en-GB"><img className='social-img' src={require('../../Assets/images/social-icons/snapchat-logo-png-1450.png')} alt='#kswty'/></a>
        </div>
        </ul>
        )}
      <div onClick={toggleNav} className="menu-btn">
      <div class="menu-btn__burger"></div>
          </div>

    </nav>
    </div>
    </div>
  )
}

export default Navbar