import React, {useState, useRef} from 'react'
import WhatsappButton from '../../Components/Whatsapp-button/index'
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { v4 as uuid } from 'uuid';
import LoadingSpinner from '../../Components/Loading-Spinner/index';
import MetaTags from 'react-meta-tags';
import './styles.css'

function OrderPage() {
  const { t } = useTranslation();
  const form = useRef();

  const [fullName, setFullName] = useState();
  const [HomeAddress, setHomeAddress] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [city, setCity] = useState();
  const [FullLocation, setFullLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const unique_id = uuid();
  const small_id = unique_id.slice(0,6);
  

 const handleKeyPress = (e) => {
    if(e.target.value.length===11) return false;   //limits to 10 digit entry
    setPhoneNumber(e?.target.value);       //saving input to state
    }

    

const postData = (e) => {
  setIsLoading(true);
      const areaname = document.getElementById("hname").value;
      const fullLoc = document.getElementById("lname").value;
      const country = document.getElementById("country").value;
      const fullname = document.getElementById("fname").value;
  
      if(fullname === '' || country === '' || areaname === '' || fullLoc === ''){   

        alert(t('EmptyFields'))
        setIsLoading(false);
      }else{
 
        
        e.preventDefault();

        setFullLocation('');
        setHomeAddress('');
        setPhoneNumber('');
        setCity('');
        setFullName('');

//       var request = new XMLHttpRequest();

//       request.open('POST', 'https://api.tookanapp.com/v2/create_task');
      
//       request.setRequestHeader('Content-Type', 'application/json');
      
//       request.onreadystatechange = function () {
//         if (this.readyState === 4) {
//           console.log('Status:', this.status);
//           console.log('Headers:', this.getAllResponseHeaders());
//           console.log('Body:', this.responseText);
//       }
//     }
      
//       var today = new Date();
//       var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
//       var time = today.getHours() + ":" + (today.getMinutes()+10) + ":" + (today.getSeconds());
//       var dateTime = date+' '+time;

//       const API_KEY = process.env.REACT_APP_API_KEY  

//       const offset = today.getTimezoneOffset();
 
// console.log(dateTime)
//       var body = {
//         'api_key': API_KEY,
//         'order_id': small_id,
//         'job_description': 'Clothes delivery',
//         'job_pickup_phone': '+971' + phoneNumber,
//         'job_pickup_name': fullName,
//         'job_pickup_email': '',
//         'job_pickup_address': HomeAddress + ',' + FullLocation + ',' + city ,
//         'job_pickup_datetime': dateTime,
//         'pickup_custom_field_template': 'Template_1',
//         'pickup_meta_data': [
//           {
//             'label': 'Price',
//             'data': '100'
//           },
//           {
//             'label': 'Quantity',
//             'data': '100'
//           }
//         ],
//         'team_id': '',
//         'auto_assignment': '0',
//         'has_pickup': '1',
//         'has_delivery': '0',
//         'layout_type': '0',
//         'tracking_link': 1,
//         'timezone': offset,
//         'fleet_id': '',
//         'p_ref_images': [
//           'http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png'
//         ],
//         'notify': 1,
//         'tags': '',
//         'geofence': 0
//       };
      
//       request.send(JSON.stringify(body));

      // Email config
      emailjs.sendForm('service_ki36gph', 'template_1ztn17y', form.current, 'AqlgoDH860GJTOJo_')
        .then((result) => {
            console.log(result.text);
            if (result.text == "OK") { setTimeout( function ( ) {  alert(t('alert') + "\r\n" + t('alertTwo')); }, 2000 ); setIsLoading(false); }
            else { alert("FAILURE!"); setIsLoading(false); }
        }, (error) => {
            alert(error.text);
        });
    }
}

const renderUser = (
  <div className="userlist-container">
    
  </div>
);  

  return (
    <div>

          <MetaTags>
            <title>kswty كسوتي</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>


      <div className='img-bg'>

        <div className='words'>
          <div>
          <p className='title-pick'><span className='highlighted'>{t('Request')}</span>{t('title')}</p>
          <p className='text-pick'>{t('titleSecond')}</p>
          </div>
        </div>

      </div>

  <div id='form' className='explaining-bg'>
   {/* Form Order */}
   
   <div className='Send-Message-Block'>
   
  
     <div className='title-form-sec'>     
      <h1 className='form-Title'>{t('NewOrderRequest')}</h1>
      <div className='form-under-line'></div>
      </div> 
      <form id='formSubmit' ref={form}>
           <input className='input-custom-one' type="text" id="fname" name="fullname" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder={t('FullName')} required/>

          <div className='input-field-custom'>
            <div className='contain-numbs'>
              <img className='uae-img' src={require('../../Assets/images/flags/icons8-united-arab-emirates-48.png')} alt='clothe-img'required/>
              <p className='code-number'>+971</p>
            </div>

              <input className='input-custom'
               lang="en"
               type="tel"
               inputmode="numeric"
               pattern="[A-Za-z0-9]"
               onPaste={(e)=>{
                e. preventDefault()
                return false;
                }} onCopy={(e)=>{
                e. preventDefault()
                return false;
                }}
               id="phonenumber" name="phonenumber" value={phoneNumber} placeholder={t('PhoneNumber')} onChange={handleKeyPress} />


          </div> 

           <select id="country" value={city} onChange={(e) => setCity(e.target.value)} name="country" placeholder={t('City')} required>
              <option value="" disabled selected>{t('City')}</option>
              <option value="Abu Dhabi">{t('AbuDhabi')}</option>
              <option value="Dubai">{t('Dubai')}</option>
              <option value="Sharjah">{t('Sharjah')}</option>
              <option value="Ajman">{t('Ajman')}</option>
              <option value="Umm Al-Quwain">{t('UmmAlQuwain')}</option>
              <option value="Fujairah">{t('Fujairah')}</option>
              <option value="Ras Al Khaimah">{t('RasAlKhaimah')}</option>
              <option value="Al Ain">{t('AlAin')}</option>
            </select>

           <input className='input-custom-one' type="text" id="lname" name="fullLoc" value={FullLocation} onChange={(e) => setFullLocation(e.target.value)} placeholder={t('FullLocationAddress')} required/>

           <input className='input-custom-one' type="text" id="hname" name="areaname" value={HomeAddress} onChange={(e) => setHomeAddress(e.target.value)} placeholder={t('HomeAddressNom')} required/>

           <input className='input-custom-one hided' type="text" id="codes" name="idCode" value={small_id} required/>

      
           {isLoading ? <LoadingSpinner /> : renderUser}
           <button onClick={postData} disabled={isLoading} type="submit" className='Contacts-Button'>{t('SendMessage')}</button>
           </form>
       </div>


  <div id='aboutUs' className='main'>
    <div className='Features-Main'>
      <h1 className='Features-Title-Mission'>{t('OurMission')}</h1>
      <div className='form-under-line-mission'></div>
      <p className='Features-Text'>{t('OurMissionTitle')}</p>

            {/* Features-boxes-1 */}
            <div className='Features-Details'>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-pointing-60.png')} alt='pointing'/>
                  <h1 className='Feature-h1'>{t('stepone')}</h1>
                  <p className='Features-Text-box'>{t('steponeDesc')}</p>
                </div>
              </div>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-location-100.png')} alt='pointing'/>
                  <h1 className='Feature-h1' >{t('steptwo')}</h1>
                  <p className='Features-Text-box'>{t('steptwoDesc')}</p>
                </div>
              </div>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-push-notifications-100.png')} alt='pointing'/>
                  <h1 className='Feature-h1'>{t('stepthree')}</h1>
                  <p className='Features-Text-box'>{t('stepthreeDesc')}</p>
                </div>
              </div>
            </div>



            {/* Features-boxes-1 */}
            <div className='Features-Details'>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-open-parcel-100.png')} alt='pointing'/>
                  <h1 className='Feature-h1'>{t('stepfour')}</h1>
                  <p className='Features-Text-box'>{t('stepfourDesc')}</p>
                </div>
              </div>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-gift-100.png')} alt='pointing'/>
                  <h1 className='Feature-h1'>{t('stepfive')}</h1>
                  <p className='Features-Text-box'>{t('stepfiveDesc')}</p>
                </div>
              </div>

              <div className='Features-Row-1'>
                <div className='Feature-1'>
                  <img className='img1' src={require('../../Assets/images/section-two/icons8-t-shirt-100.png')} alt='pointing'/>
                  <h1 className='Feature-h1'>{t('stepsix')}</h1>
                  <p className='Features-Text-box'>{t('stepsixDesc')}</p>
                </div>
              </div>
            </div>

    </div>
    




      <h1 id='services' className='Features-Title'>{t('RequestingOrder')}</h1>
      <div className='under-line'></div>
        <div className='explaining-block'>
            <img className='explaining-img' src={require('../../Assets/images/animation/Thrift shop-bro.png')} alt='clothe-img'/>
            <p className='explaining-text'>{t('reqOrderOneText')} <br></br> <br></br> {t('reqOrderTwoText')}</p>
        </div>


   
     </div>

      </div>
    
     <WhatsappButton/>
      </div>
  )
}

export default OrderPage