import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import './styles.css';

function IntroPage() {

  return (
    <div className='container'>

          <MetaTags>
            <title>kswty LinkTree</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>

      <div className='inner-container'>


{/* section info */}
 <div className='text-info-links'>
        <img className='logo-img' src={require('../../../Assets/images/logos/Design-2 - Copy.png')} alt='logo'/>
        <p className='text-into'>kswty for recycled clothes  ستار دريس لإعادة تدوير الملابس</p>
        <p className='text-into-sec'>Get an extra value from your extra clothes مشروع الإستفادة من فائض الملابس</p>
</div>

  {/* section buttons */}
<div>
    <div className='buttons-intro'>
          <button className='button-flex'>
          <FontAwesomeIcon
          icon={faWhatsapp}
          style={{ fontSize: 40, color: "white" }}
          className='button-flex-img'/>
            <p><a href="whatsapp://send?text=Hello World!&phone=+971565388999">طلب استلام عبر الوتساب</a></p>
          </button>

          
          <button className='button-flex'>
          <FontAwesomeIcon
          icon={faWindowMaximize}
          style={{ fontSize: 40, color: "white" }}
          className='button-flex-img'/>
            <Link className='link-decoration' to="/"><p>طلب استلام عبر الموقع</p></Link>
          </button>

          <button className='button-flex'>
          <FontAwesomeIcon
          icon={faWhatsapp}
          style={{ fontSize: 40, color: "white" }}
          className='button-flex-img'/>
            <p><a href="whatsapp://send?text=Hello World!&phone=+971565388999">Pickup Order - WhatsApp</a></p>
          </button>

          <Link style={{ textDecoration: 'none' }} to="/">
          <div className='button-flex'>
          <FontAwesomeIcon
          icon={faWindowMaximize}
          style={{ fontSize: 40, color: "white" }}
          className='button-flex-img' />
            <p>Pickup Order - Website</p>
          </div>
          </Link>
    </div>

    <div className='social-media-flex'>
    <a href="whatsapp://send?text=Hello World!&phone=+971565388999"><img className='social-img' src={require('../../../Assets/images/social-icons/whatsapp-green.png')} alt='social-icon'/></a>
          <a href="https://instagram.com/kswty_?igshid=YmMyMTA2M2Y="><img className='social-img' src={require('../../../Assets/images/social-icons/instagram-green.png')} alt='social-icon'/></a>
          <a href="https://www.facebook.com/profile.php?id=100085481397299"><img className='social-img' src={require('../../../Assets/images/social-icons/fb-green.png')} alt='social-icon'/></a>
          <a href="#"><img className='social-img' src={require('../../../Assets/images/social-icons/tiktok-green.png')} alt='social-icon'/></a>
          <a href="mailto:kswtyrecycling@gmail.com"><img className='social-img' src={require('../../../Assets/images/social-icons/email-green.png')} alt='social-icon'/></a>
          <a href="https://twitter.com/KswtyOperations?t=bHO8NSRdrlfrDwIv6Nqkaw&s=09"><img className='social-img' src={require('../../../Assets/images/social-icons/twitter-green.png')} alt='social-icon'/></a>
    </div>
</div>

      
        


      </div>
    </div>
  )
}

export default IntroPage