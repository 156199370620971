import  React from 'react'
import './styles.css'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function WhatsappButton() {

    return (
        <div className="floating-container">
        <div className="floating-button">
          
                <a href="whatsapp://send?text=Hello World!&phone=+971565388999">
                <FontAwesomeIcon
                icon={faWhatsapp}
                style={{ fontSize: 40, color: "white" }}
                className='fa-brands fa-whatsapp text-white iconsi'
                />
                </a>
                
                </div>
                </div>
        
    )
}

export default WhatsappButton
