import React from 'react'
import { useTranslation } from 'react-i18next';
import './styles.css'

function Footer() {
	const { t, i18n } = useTranslation();
  return (
    <div>
      
<footer class="footer-distributed">

			<div class="footer-left">

      <img className='logo-img-header' src={require('../../Assets/images/logos/Logo-full.png')} alt='logo'/>

				<p class="footer-links">
					<a href="#" class="link-1">{t('Home')} </a>
					
					<a href="#services">{t('Services')} </a>
				
					<a href="#aboutUs">{t('Contact')} </a>
				
					<a href="#form">{t('PickupOrder')} </a>
					
				</p>

		
				<p class="footer-company-name">{t('titleLogoFooter')} © 2022</p>
			</div>

			<div class="footer-center">

				<div>
					<i class="fa fa-map-marker"></i>
					<p><span>Bur Dubai,</span>  Dubai, United Arab Emirates </p>
				</div>
				
				<div>
					<i class="fa fa-phone"></i>
					<p>+971565388999</p>
				</div>

				<div>
					<i class="fa fa-envelope"></i>
					<p><a href="mailto:kswtyrecycling@gmail.com">kswtyrecycling@gmail.com</a></p>
				</div>

			</div>

			<div class="footer-right">

				<p class="footer-company-about">
					<span>{t('aboutUs')}</span>
					{t('aboutUsInfo')}
				</p>

				<div class="footer-icons">

        <div className='.social-media-flex-footer'>
		<a href="whatsapp://send?text=Hello World!&phone=+971565388999"><img className='social-img' src={require('../../Assets/images/social-icons/whatsapp-green.png')} alt='#kswty'/></a>
          <a href="https://www.instagram.com/kswty_ae/"><img className='social-img' src={require('../../Assets/images/social-icons/instagram-green.png')} alt='#kswty'/></a>
          <a href="https://www.facebook.com/profile.php?id=100085481397299"><img className='social-img' src={require('../../Assets/images/social-icons/fb-green.png')} alt='#kswty'/></a>
          <a href="http://www.tiktok.com/@kswty_uae"><img className='social-img' src={require('../../Assets/images/social-icons/tiktok-green.png')} alt='#kswty'/></a>
          <a href="mailto:kswtyrecycling@gmail.com"><img className='social-img' src={require('../../Assets/images/social-icons/email-green.png')} alt='#kswty'/></a>
          <a href="https://twitter.com/kswty_ae"><img className='social-img' src={require('../../Assets/images/social-icons/twitter-green.png')} alt='#kswty'/></a>
		  <a href="https://www.snapchat.com/add/kswty_ae?share_id=dncBimNJy0U&locale=en-GB"><img className='social-img' src={require('../../Assets/images/social-icons/snapchat-logo-png-1450.png')} alt='#kswty'/></a>
        </div>

				</div>

			</div>

		</footer>
    </div>
  )
}

export default Footer