import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import IntroPage from './Pages/Main-Intro-Page/IntroPage/index';
import OrderPage from './Pages/Order-Page/Index';
import Header from './Components/Header/Index';
import Footer from './Components/Footer/Index';


function App() {

  return (


    <Router> 
    <div className="app">

      <Routes>

      <Route path="/LinksPage" element={<IntroPage/>} /> 

      <Route path="/" 
      element={
      <>
      <Header />
      <OrderPage />
      <Footer />
      </>
      } /> 

      </Routes>

    </div>
    </Router>




  );
}

export default App;
